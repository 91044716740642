:root {
    --brand-primary            : #d5001c;
    --brand-primary-lighten-10 : #ff0929;
    --brand-primary-lighten-40 : #ffa2ae;
    --brand-primary-darken-5   : #bc0019;
    --brand-primary-darken-10  : #a20015;
    --brand-primary-darken-12  : #980014;
    --brand-primary-opacity-25 : #d5001c40;
    --brand-primary-opacity-50 : #d5001c80;

    --brand-secondary          : var(--brand-primary);

    --highlight                : #ff9daa;
    --highlight-tint           : #ffe7ea;

    --navbar-height            : 60px;
    --navbar-brand-width       : 275px;
    --navbar-brand-height      : 37px;
    --navbar-brand-margin-left : -16px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/clients/porsche-na/navbar-brand-logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/clients/porsche-na/navbar-brand-logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : contain;
    --login-logo-height        : auto;
    --login-logo-width         : 200px;
    --login-logo               : url('/assets/images/PoweredByShift.png');
    --login-brand-logo         : url('/assets/images/clients/porsche-na/login-logo.png');
    --login-brand-logo-width   : 250px;
    --login-brand-logo-height  : 126px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;

    --combined-bar-green       : #658e11;
    --combined-bar-blue        : #2a4b6d;
    --combined-bar-yellow      : #d88711;
    --combined-bar-red         : #94001b;
}